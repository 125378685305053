import PropTypes from 'prop-types'

import styles from './Heading.module.scss'

const Heading = ({ className, children, level = 1 }) => {
  if (level > 6) {
    level = 6
  }
  const Component = `h${level}`
  return <Component className={className}>{children}</Component>
}

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6])
}

export default Heading
