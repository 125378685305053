const StitcherPodcast = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 51.61">
      <path fill="#3590c5" d="M0 10.14h21.5v39.01H0V10.14z" />
      <path fill="#c53827" d="M101.38 7.99h21.5v35.94h-21.5V7.99z" />
      <path fill="#ec8b24" d="M76.19 0h21.5v46.7h-21.5V0z" />
      <path fill="#fbc618" d="M51 6.76h21.5v44.85H51V6.76z" />
      <path fill="#612268" d="M25.5 3.07H47v43.32H25.5V3.07z" />
    </svg>
  )
}

export default StitcherPodcast
