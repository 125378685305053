import React, { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './Tags.module.scss'
import Tag from 'basics/Tag'
import { ClassNames } from '../../design-system/src/services/classname.service'
import { nanoid } from 'nanoid'

const Tags = ({ className, tags = [], alignment = 'justify-content-center', limit = 3 }) => {
  const [showAll, setShowAll] = useState(false)
  return (
    <div
      className={ClassNames([styles['ds-tags'], className, 'display-flex flex-wrap', alignment])}
    >
      {tags.slice(0, showAll ? tags.length : limit).map((tag) => (
        <Tag slug={tag.slug} type={tag.type} key={nanoid()}>
          {typeof tag === 'string' ? tag : tag.name}
        </Tag>
      ))}
      {tags.length > limit && (
        <button onClick={() => setShowAll(!showAll)}>{showAll ? <>Hide</> : <>See More</>}</button>
      )}
    </div>
  )
}

Tags.propTypes = {}

export default Tags
