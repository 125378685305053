import PropTypes from 'prop-types'

import styles from './MenuSection.module.scss'
import { ClassNames } from 'design-system/src/services/classname.service'
import { PickFirst } from 'services/utils'
import Link from 'basics/Link'
import { nanoid } from 'nanoid'

const LinkList = ({ links, footer }) => {
  if (links.length > 0) {
    return (
      <ul>
        {links.map((link) => {
          const targetPage = PickFirst(link.targetPage) || null
          return (
            <li
              key={nanoid()}
              className={ClassNames([styles[`menu-section__${link.type || 'normal'}`], 'pb-3'])}
            >
              {targetPage && (
                <Link
                  href={`/${targetPage.url}`}
                  chevron={!footer ? true : null}
                  className={ClassNames([link.type === 'highlighted' ? 'font-weight-bold' : ''])}
                >
                  {targetPage.name}
                </Link>
              )}
              {!targetPage && link.pageTitle}
            </li>
          )
        })}
      </ul>
    )
  }
  return null
}

const MenuSection = ({ className, data = {}, destination, external, footer }) => {
  const { headline, description, links = [] } = data || {}
  return (
    <div className={styles['menu-section']}>
      {headline && !footer && (
        <div className="h4 mb-3">
          <Link href={destination} external={external} chevron={true}>
            {headline}
          </Link>
        </div>
      )}
      {description && !footer && (
        <div
          className={styles['menu-section__description']}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {links && <LinkList links={links || []} footer={footer} />}
      {/* {links.length === 0 && (
        <Link href={destination} external={external} chevron={true}>
          View {headline}
        </Link>
      )} */}
    </div>
  )
}

MenuSection.propTypes = {}

export default MenuSection
