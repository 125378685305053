import { PickFirst } from 'services/utils'
import Button from 'basics/Button'
import { nanoid } from 'nanoid'
import Link from 'basics/Link'
import useMarketo from 'hooks/useMarketo'
import MarketoForm from 'components/MarketoForm'

export const MarketoCTA = ({ id, text, buttonType, message, thankYouPage }) => {
  const { onLoadForm, onRemoveForm, formLoaded, formSuccess } = useMarketo({
    munchkinId: '880-QNO-957',
    formId: id,
    callback: () => {}
  })

  return (
    <>
      <Button type={buttonType} onClick={onLoadForm}>
        {text}
      </Button>

      <MarketoForm
        formId={id}
        isLoaded={formLoaded}
        onRemoveForm={onRemoveForm}
        success={formSuccess}
        successMessage={message ? message : "Thank you for subscribing."}
        thankYouPage={thankYouPage}
      />
    </>
  )
}

const CtaWidgets = ({ className, ctaWidgets = [], type }) => {
  const onClick = () => {}

  return (
    <div className={className}>
      {ctaWidgets &&
        ctaWidgets.map((cta) => {
          let externalLink = cta.externalLink || cta.linkUrl || null
          let link = ''
          if (cta.linkedItem && cta.linkedItem.length > 0) {
            const linkedItem = PickFirst(cta.linkedItem)
            link = linkedItem?.url || ''
          } else if (
            cta.referencingBasePageContents &&
            cta.referencingBasePageContents.length > 0
          ) {
            const linkedItem = PickFirst(cta.referencingBasePageContents)
            link = linkedItem?.url || ''
          }
          let buttonType = type
          if (cta.hollow) {
            buttonType = 'hollow'
          }

          if (cta.secondary) {
            buttonType = 'secondary'
          }

          if (cta.tertiary) {
            buttonType = 'tertiary'
          }
          return (
            <span key={nanoid()} className={ctaWidgets.length > 1 ? "mr-2 cta" : "cta"}>
              {link && !externalLink && (
                <Button url={`/${link}`} type={buttonType}>
                  {cta.text}
                </Button>
              )}
              {cta.email && (
                <Button url={'mailto:' + cta.email} type={buttonType}>
                  {cta.text}
                </Button>
              )}
              {externalLink && !cta.marketoId && (
                <Link
                  href={externalLink}
                  external={
                    !externalLink.includes('firstbusiness.bank') &&
                    !externalLink.includes('amplifyapp.com') &&
                    !externalLink.includes('cloudfront.net') &&
                    externalLink.includes('http')
                      ? true
                      : false
                  }
                >
                  <Button type={buttonType} buttonType="div">
                    {cta.text}
                  </Button>
                </Link>
              )}
              {cta.marketoId && (
                <MarketoCTA
                  id={cta.marketoId}
                  text={cta.text}
                  buttonType={buttonType}
                  message={cta.marketoConfirmation}
                  thankYouPage={cta.thankYouPage}
                />
              )}
            </span>
          )
        })}
    </div>
  )
}

CtaWidgets.propTypes = {}

export default CtaWidgets
