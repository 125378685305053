import React, { useRef, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ClassNames } from 'services/classname.service'
import { CdnUrl } from 'services/image.service'
import { nanoid } from 'nanoid'

import styles from './Header.module.scss'

import Container from 'containers/Container'
import MegaMenu from 'features/MegaMenu'
import { NavStateContext } from 'providers/nav'

function Header({
  className,
  logo = CdnUrl('logo.svg'),
  navItems = [],
  route = [],
  open
}) {
  const { menuOpen, toggleMenu } = useContext(NavStateContext) || {}
  const refs = [useRef(), useRef(), useRef()]

  const openMenu = (idx) => {
    const selected = refs[idx]
    const isOpen = selected.current?.classList.contains(styles['open'])
    refs.forEach((ref) => ref.current?.classList.remove(styles['open']))
    if (!isOpen) {
      selected.current?.classList.add(styles.open)
      setTimeout(() => toggleMenu(idx), 600)
    } else {
      setTimeout(() => toggleMenu(-1), 200)
    }
  }

  useEffect(() => {
    if (menuOpen === -1) {
      refs.forEach((ref) => ref.current?.classList.remove(styles['open']))
    }
  }, [menuOpen])

  return (
    <Container className={className} wide={true}>
      <div
        className={ClassNames([styles['overlay'], menuOpen >= 0 ? styles.open : null])}
        onClick={() => toggleMenu(-1)}
      ></div>
      <header
        className={ClassNames([styles[`ds-header`], `display-grid align-items-center`])}
        open={open}
      >
        {logo && (
          <div className={ClassNames([styles['logo'], 'md-display-none'])}>
            <a href="/" aria-label="First Business Bank Home">
              <picture>
                <source srcSet={logo} />
                <img src={logo} width="178" height="71" alt="First Business Bank" />
              </picture>
            </a>
          </div>
        )}
        <nav className={styles['nav']} aria-label="Main Navigation">
          <ul className={ClassNames([styles['ul'], 'display-grid'])}>
            {navItems.map((link, idx) => {
              const page = link.page[0] || {}
              const title = link.linkTitle || page.name
              const external = link.alternateLink ? true : null
              const destination = link.alternateLink || `/${page.url}`
              return (
                <li
                  key={nanoid()}
                  className={ClassNames([
                    styles['li'],
                    page.url.split('/')[0] === route[0] ? styles['active'] : '',
                    menuOpen === idx ? styles.open : ''
                  ])}
                  ref={refs[idx]}
                >
                  <>
                    {/* <Link href={destination} external={external}> */}
                    <button
                      className={styles['toggle']}
                      onClick={() => {
                        const width = window.innerWidth
                        if (width > 768) {
                          openMenu(idx)
                        } else {
                          const ref = refs[idx]
                          if (ref.current) {
                            ref.current.classList.toggle(styles['expanded'])
                          }
                        }
                      }}
                    >
                      {title}
                    </button>
                    {/* </Link> */}
                    <button
                      className={styles['expand-button']}
                      onClick={() => {
                        const ref = refs[idx]
                        if (ref.current) {
                          ref.current.classList.toggle(styles['expanded'])
                        }
                      }}
                    >
                      <span className={ClassNames([styles['triangle'], 'rotate'])}></span>
                      <span className="sr-only">Expand</span>
                    </button>
                  </>
                  <MegaMenu
                    destination={destination}
                    external={external}
                    menuSections={link.menuSections || []}
                    menuClass={styles['mega-menu']}
                    menuContentClass={styles['mega-menu__content']}
                    overlayClass={styles['overlay']}
                  />
                </li>
              )
            })}
          </ul>
        </nav>
      </header>
    </Container>
  )
}

Header.propTypes = {
  logo: PropTypes.string,
  condensed: PropTypes.bool,
  title: PropTypes.string,
  navItems: PropTypes.array,
  ariaLabel: PropTypes.string
}

export default Header
