import PropTypes from 'prop-types'

import styles from './Tag.module.scss'
import Link from 'basics/Link'

const Tag = ({ className, children, slug, type }) => {
  if (slug && type) {
    return (
      <Link href={`/resource-center/by/${type}/${slug}`} className={`${styles['ds-tag']}`}>
        {children}
      </Link>
    )
  }
  return <span className={`${styles['ds-tag']}`}>{children}</span>
}

Tag.propTypes = {}

export default Tag
