const cloudinaryMap = Object.freeze({
  width: 'w_',
  height: 'h_',
  gravity: 'g_',
  crop: 'c_'
})

const formatMap = Object.freeze({
  format: 'f_',
  quality: 'q_'
})

const formatCloudinaryOptions = (props, map = cloudinaryMap) => {
  const options = Object.entries(props)
    .map(([key, value]) => {
      const cloudinaryKey = map[key]
      if (value && cloudinaryKey) {
        return `${cloudinaryKey}${value}`
      }
    })
    .join(',')
  if (options) {
    return `/${options}`
  }
  return ''
}

export const ImageConfig = Object.freeze({
  prefix: {
    local: () => process.env.IMAGE_LOCAL,
    cloudinary: (props) => `${process.env.IMAGE_CDN}${formatCloudinaryOptions(props)}/f_auto,q_auto/${process.env.IMAGE_CDN_FOLDER}/`
  },
  squareDimensions: {
    height: '519',
    width: '547'
  }
})

export const CdnUrl = (filename, format = {}, size = {}) => {
  const imageOptions = `${formatCloudinaryOptions(size)}${formatCloudinaryOptions(format, formatMap)}`

  return `${process.env.IMAGE_CDN}${imageOptions}/${process.env.IMAGE_CDN_FOLDER}/${filename}`
}
