const Envelope = () => {
  return (
    <svg
      className="display-inline-block"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1792 1792"
      width="20"
    >
      <path
        fill="#ffffff"
        d="M1792 710v794q0 66-47 113t-113 47H160q-66 0-113-47T0 1504V710q44 49 101 87 362 246 497 345 57 42 93 66t94 48 110 24h2q51 0 110-24t95-48 92-66q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123l-468 325-42 31q-32 24-54 38t-52 32-58 27-50 9h-2q-23 0-50-9t-57-27-52-32-54-38-43-31L377 833Q206 715 172 690q-62-42-117-115T0 438q0-78 42-130t118-52h1472q65 0 113 47t47 113z"
      />
    </svg>
  )
}

export default Envelope
