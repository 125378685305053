import React, { useState } from 'react'
import styles from './BannerAlert.module.scss'
import { ClassNames } from '../../services/classname.service'

const BannerAlert = ({ className, children, onDismiss = () => {} }) => {
  const [visible, setVisible] = useState(true)
  return (
    <aside
      className={ClassNames([
        styles[`ds-banner-alert`],
        `place-content-center`,
        !visible ? styles['display-none'] : ''
      ])}
    >
      <div
        className={ClassNames([
          styles['ds-banner-alert__text'],
          'display-flex flex-direction-col align-items-center mr-4'
        ])}
      >
        <svg className="display-inline-block mr-2" width="16" height="16" viewBox="0 0 16 16">
          <path d="M0,8a8,8,0,1,1,8,8A8,8,0,0,1,0,8Z" fill="rgba(255,255,255,0.6)" />
          <path
            d="M1,0A.91.91,0,0,0,0,.778V6.222A.91.91,0,0,0,1,7a.91.91,0,0,0,1-.778V.778A.91.91,0,0,0,1,0Z"
            transform="translate(7 3)"
            fill="rgba(255,255,255,0.6)"
          />
          <circle cx="1" cy="1" r="1" transform="translate(7 11)" fill="rgba(255,255,255,0.6)" />
        </svg>
        <div>{children}</div>
      </div>
      <button
        onClick={() => {
          onDismiss()
          setVisible(false)
        }}
      >
        <span className="sr-only">Close Alert</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15.556"
          height="15.556"
          viewBox="0 0 15.556 15.556"
          className="ds-banner-alert__close"
        >
          <span className="sr-only">Close Message</span>
          <g transform="translate(-0.222 -0.555)">
            <rect
              width="20"
              height="2"
              rx="1"
              transform="translate(1.636 0.555) rotate(45)"
              fill="#762a5b"
            />
            <rect
              width="20"
              height="2"
              rx="1"
              transform="translate(0.222 14.697) rotate(-45)"
              fill="#762a5b"
            />
          </g>
        </svg>
      </button>
    </aside>
  )
}

BannerAlert.propTypes = {}

export default BannerAlert
