const LinkedIn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="20">
      <path
        fill="#ffffff"
        d="M477 625v991H147V625h330zm21-306q1 73-50 122t-136 49h-2q-82 0-132-49t-50-122q0-74 52-122t134-49 133 49 51 122zm1166 729v568h-329v-530q0-105-40-164t-127-60q-63 0-105 35t-64 85q-11 30-11 81v553H659l2-647-1-296-1-48h329v144h-2q20-32 41-56t57-52 87-43 114-16q171 0 275 114t104 332z"
      />
    </svg>
  )
}

export default LinkedIn
