import PropTypes from 'prop-types'
import styles from './FlexContainer.module.scss'
import { ClassNames } from 'services/classname.service'

const FlexContainer = ({
  gapSize = 32,
  flexWrap = 'flex-wrap',
  flexDir = '',
  className = '',
  justify = '',
  children,
  id
}) => {
  const elementStyle = {
    gap: gapSize + 'px'
  }
  return (
    <section
      id={id}
      style={elementStyle}
      className={ClassNames([
        styles['ds-flex'],
        'flex-container',
        `display-flex ${flexWrap} ${flexDir} ${className} ${justify}`
      ])}
    >
      {children}
    </section>
  )
}

FlexContainer.propTypes = {
  gap: PropTypes.number,
  className: PropTypes.string,
  flexWrap: PropTypes.oneOf(
    Object.keys({
      wrap: 'flex-wrap',
      wrapReverse: 'flex-wrap-reverse',
      noWrap: 'flex-nowrap'
    })
  ),
  flexDir: PropTypes.oneOf(
    Object.keys({
      row: 'row',
      rowReverse: 'row-reverse',
      column: 'column',
      columnReverse: 'column-reverse'
    })
  )
}

export default FlexContainer
