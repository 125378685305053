import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import styles from './MegaMenu.module.scss'
import { ClassNames } from 'design-system/src/services/classname.service'
import MenuSection from 'components/MenuSection'
import { nanoid } from 'nanoid'

const MegaMenu = ({
  className,
  overlayClass,
  menuClass,
  menuContentClass,
  menuSections = [],
  destination,
  external
}) => {
  // const { menuOpen, toggleMenu } = useContext(NavStateContext) ||
  return (
    <div>
      {/* <div className={ClassNames([styles['overlay'], overlayClass])}></div> */}
      <div className={ClassNames([styles['mega-menu'], menuClass])} aria-label='header category navigation'>
        <div className={ClassNames([styles['mega-menu__content'], 'md-mt-4', menuContentClass])}>
          {menuSections.map((section) => (
            <MenuSection
              data={section}
              key={nanoid()}
              destination={destination}
              external={external}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

MegaMenu.propTypes = {}

export default MegaMenu
