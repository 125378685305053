import { useState, useEffect } from 'react'

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true)

  const script = document.createElement('script')
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null)
  document.body.appendChild(script)
}

function destyleMktoForm(mktoForm, options) {
  var formEl = mktoForm.getFormElem()[0],
    arrayFrom = Function.prototype.call.bind(Array.prototype.slice),
    options = options || {}

  // remove element styles from <form> and children

  var styledEls = arrayFrom(formEl.querySelectorAll('[style]')).concat(formEl)

  styledEls.forEach(function (el) {
    el.removeAttribute('style')
  })

  // disable remote stylesheets and local <style>s
  var styleSheets = arrayFrom(document.styleSheets)

  styleSheets.forEach(function (ss) {
    try {
      if (
        [mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(ss.ownerNode) != -1 ||
        formEl.contains(ss.ownerNode)
      ) {
        ss.disabled = true
      }
    } catch (e) {}
  })
  formEl.setAttribute('data-styles-ready', 'true')
}

function useMarketo({ baseUrl = 'https://app-ab23.marketo.com', munchkinId, formId, callback }) {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [formLoaded, setFormLoaded] = useState(false)
  const [formDisplaying, setFormDisplaying] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)

  useEffect(() => {
    if (!formLoaded) {
      return
    }
    if (scriptLoaded && !formDisplaying) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, (form) => {
        setFormDisplaying(true)
        form.getFormElem()[0]?.querySelector('input')?.focus()
        form.onSuccess(() => {
          setFormSuccess(true)
          form.getFormElem().hide()
          callback()
          return false
        })
      })
      MktoForms2.whenReady(function (form) {
        document.querySelector('#mktoForms2BaseStyle')?.remove()
        document.querySelector('#mktoForms2ThemeStyle')?.remove()
      })
      window.MktoForms2.whenRendered(function (form) {
        destyleMktoForm(form, { keepInline: false, keepSheets: false })
      })
      return
    }
    appendScript(baseUrl, setScriptLoaded)
  }, [scriptLoaded, formLoaded, baseUrl, munchkinId, formId, callback])

  const onLoadForm = () => {
    setFormLoaded(true)
    // change z-indexes so form stays on top
    document.getElementById('fbbMasthead').style.zIndex = 4;
    let form = document.getElementById('marketoForm');
    if(form) {
      form.style.zIndex = 100;
    }
  }

  const onRemoveForm = (ev) => {
    let form = document.getElementById('marketoForm');
    if (!ev || (ev?.target === ev?.currentTarget)) {
      setFormLoaded(false)
      setFormDisplaying(false)
      setFormSuccess(false)
    }
    // only remove the form if background or close button are clicked
    if(ev?.target?.id === "marketo-form-background" || ev?.target?.id === "marketo-close"){
      if(form) {
        form.style.zIndex = 10;
      }
      // change z-indexes back so services nav stays on top of cta button
      document.getElementById('fbbMasthead').style.zIndex = 12;
    } else {
      // because form is inside background element, change the z-index when form is clicked
      if(form) {
        form.style.zIndex = 100;
      }
    }
  }

  return { onLoadForm, onRemoveForm, formLoaded, formSuccess }
}

export default useMarketo
