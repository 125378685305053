import { CdnUrl } from './image.service'

const Website = ({ meta, applicationSettings, pageTypes }) => {
  return {
    '@type': 'WebSite',
    '@id': `${applicationSettings.siteurl}#website`,
    url: applicationSettings.siteurl,
    name: applicationSettings.sitename,
    inLanguage: 'en-US',
    potentialAction: {
      '@type': 'SearchAction',
      target: `${applicationSettings.siteurl}/?keyword={search_term_string}`,
      'query-input': 'required name=search_term_string'
    }
  }
}

const WebPage = ({ meta, applicationSettings, pageTypes }) => {
  const fullUrl = `${applicationSettings.siteurl}/${meta.url !== '/' ? meta.url : ''}`
  return {
    '@type': 'WebPage',
    '@id': `${fullUrl}#webpage`,
    url: fullUrl,
    name: meta.title,
    isPartOf: {
      '@id': `${applicationSettings.siteurl}#website`
    },
    inLanguage: 'en-US',
    datePublished: '2017-06-21 15:45:12',
    dateModified: '2021-02-17 11:11:56',
    description: meta.description,
    potentialAction: [
      {
        '@type': 'ReadAction',
        target: [fullUrl]
      }
    ]
  }
}

const ImageObject = ({ meta, applicationSettings, pageTypes }) => {
  if (!meta.metaImage) {
    return null
  }
  const fullUrl = `${applicationSettings.siteurl}/${meta.url !== '/' ? meta.url : ''}`
  return {
    '@type': 'ImageObject',
    '@id': `${fullUrl}#primaryimage`,
    inLanguage: 'en-US',
    url: CdnUrl(meta.metaImage[0], {quality: 'auto', format: 'auto', resolution: 'auto'}),
    width: 1920,
    height: 768
  }
}

const Article = ({ meta, applicationSettings, pageTypes }) => {
  const fullUrl = `${applicationSettings.siteurl}/${meta.url !== '/' ? meta.url : ''}`
  let image
  if (meta.metaImage) {
    image = metaImage[0]
  }
  const structuredData = {
    '@type': 'Article',
    '@id': `${fullUrl}#article`,
    isPartOf: {
      '@id': `${fullUrl}#website`
    },
    inLanguage: 'en-US',
    headline: meta.title,
    mainEntityOfPage: {
      '@id': `${fullUrl}#webpage`
    },
    datePublished: '2021-02-03 11:04:44',
    dateModified: '2021-02-04 16:48:41'
  }

  if (image) {
    structuredData.image = {
      '@id': CdnUrl(`${image}#primaryimage`, {resolution: 'auto', format: 'auto', quality: 'auto'}, {width: '1920'})
    }
  }

  return structuredData
}

export const CompileStructuredData = ({ meta, applicationSettings, pageTypes }) => {
  const graph = {
    '@context': 'https://schema.org',
    '@graph': []
  }

  const add = (obj) => graph['@graph'].push(obj)

  if (pageTypes.includes('website')) {
    add(Website({ meta, applicationSettings, pageTypes }))
  }

  if (pageTypes.includes('webpage')) {
    add(WebPage({ meta, applicationSettings, pageTypes }))
  }

  if (pageTypes.includes('image')) {
    add(ImageObject({ meta, applicationSettings, pageTypes }))
  }

  if (pageTypes.includes('article')) {
    add(Article({ meta, applicationSettings, pageTypes }))
  }

  return JSON.stringify(graph, null, 2)
}
