const Twitter = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#FFFFFF"
        d="M31.993 6.077a13.027 13.027 0 01-3.77 1.033 6.588 6.588 0 002.887-3.63 13.144 13.144 0 01-4.168 1.592A6.56 6.56 0 0022.149 3a6.562 6.562 0 00-6.562 6.563c0 .514.057 1.016.169 1.496-5.455-.274-10.291-2.887-13.529-6.858a6.544 6.544 0 00-.888 3.3 6.564 6.564 0 002.919 5.464 6.544 6.544 0 01-2.972-.821l-.001.082a6.568 6.568 0 005.265 6.437 6.606 6.606 0 01-2.963.113 6.572 6.572 0 006.13 4.558 13.166 13.166 0 01-8.15 2.811c-.53 0-1.053-.031-1.566-.092A18.592 18.592 0 0010.062 29c12.072 0 18.675-10.001 18.675-18.675 0-.284-.008-.568-.02-.85a13.315 13.315 0 003.276-3.398z"
      />
    </svg>
  )
}

export default Twitter
