import PropTypes from 'prop-types'
import styles from './Container.module.scss'
import { ClassNames } from 'services/classname.service'

const Widths = Object.freeze({
  normal: 'normal',
  narrow: 'narrow',
  wide: 'wide'
})

function Container({
  children,
  className = '',
  fullWidth = false,
  fullHeight = false,
  narrow = false,
  wide = false,
  color = 'light',
  hasShadow,
  width = 'normal',
  zIndex = '',
  id,
  center = true
}) {
  return (
    <section
      id={id}
      style={{zIndex:zIndex}}
      className={ClassNames([
        styles[`ds-container`],
        styles[`ds-container--${color}`],
        center ? 'mx-auto' : '',
        className,
        fullWidth ? styles['ds-container--full-width'] : '',
        fullHeight ? styles['ds-container--full-height'] : '',
        styles[`ds-container--${width}`],
        narrow ? styles['ds-container--narrow'] : '',
        wide ? styles['ds-container--wide'] : '',
        hasShadow ? styles['ds-container--shadow'] : ''
      ])}
    >
      {children}
    </section>
  )
}

Container.propTypes = {
  fullWidth: PropTypes.bool,
  color: PropTypes.string,
  maxWidth: PropTypes.string,
  className: PropTypes.string
}

export default Container
