import React, { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import styles from './MarketoForm.module.scss'
import { ClassNames } from 'services/classname.service'
import { useEscape } from 'hooks/useEscape'

const MarketoForm = ({
  className,
  formId,
  isLoaded = false,
  onRemoveForm,
  successMessage,
  success = false,
  thankYouPage
}) => {
  const ref = useRef()
  useEscape({ onCancel: onRemoveForm })
  const router = useRouter()
// Temporary tracking ids until new thank you pages are in place
let trackingId = 0
  // Newsletter
  if(formId==='3197'){
    trackingId = 8
  }
  // Weekly economic update
  if(formId==='3215'){
    trackingId = 7
  }
  // Event registration
  if(formId==='3313'){
    trackingId = 9
  }
  // Test form
  if(formId==='3368'){
    trackingId = 10
  }

  useEffect(() => {
    if (success) {
      if(thankYouPage) {
        onRemoveForm()
        const thankYouPageUrl = thankYouPage[0].pageBase[0].url
        router.push(`/${thankYouPageUrl}`)
      } else {
        setTimeout(() => {
          onRemoveForm()
        }, 3000)
      }
    }
  }, [success, onRemoveForm, thankYouPage])

  if (!isLoaded) {
    return null
  }

  return (
    <div
      id="marketo-form-background"
      className={ClassNames([styles['marketo-form'], isLoaded ? styles['visible'] : null])}
      onClick={onRemoveForm}
    >
      <div id="marketo-form-content" className={styles['marketo-form__content']}>
        <button id="marketo-close" className={styles.close} onClick={onRemoveForm}>
          Close
        </button>
        <form ref={ref} id={`mktoForm_${formId}`}></form>
        {success && !thankYouPage && (
          <>
            <div
              className="intro-paragraph font-weight-bold mt-4 mb-4"
              dangerouslySetInnerHTML={{ __html: successMessage }}
            />
            <div id={`thank-you-goal-${trackingId || ''}`}></div>
          </>
        )}
      </div>
    </div>
  )
}

MarketoForm.propTypes = {}

export default MarketoForm
