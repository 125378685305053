import { useEffect } from 'react'

export const useEscape = ({ onCancel }) => {
  useEffect(() => {
    document.addEventListener('keyup', (ev) => {
      if (ev.key === 'Escape') {
        onCancel()
      }
    })
  })
}
