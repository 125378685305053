import { ClassNames } from '../../services/classname.service'
import styles from './Layout.module.scss'
import { PickFirst } from 'services/utils'

const Backgrounds = {}

function Layout({
  children,
  className,
  overlay,
  background,
  image,
  backgroundConfig = {},
  overflow,
  hasAnnouncement = false
}) {
  const color = PickFirst(backgroundConfig?.color)?.shortName
  const imageName = PickFirst(backgroundConfig?.image)?.imageName
  const aligmentName = PickFirst(backgroundConfig?.alignment)?.shortName

  return (
    <main
      className={ClassNames([
        styles['layout'],
        imageName ? styles['with-background'] : '',
        color ? styles[color] : '',
        aligmentName ? styles[aligmentName] : '',
        overlay ? styles[overlay] : '',
        imageName ? styles[imageName] : '',
        background ? styles[background] : '',
        image ? styles[image] : '',
        overflow ? styles['show-overflow'] : '',
        hasAnnouncement ? styles['has-announcement'] : ''
      ])}
    >
      {children}
    </main>
  )
}

export default Layout
