import PropTypes from 'prop-types'
import styles from './Button.module.scss'

import Icon from '../Icon'
import Link from '../../../../basics/Link'
import Loading from '../Loading'
import { ClassNames } from 'services/classname.service'

const IconSides = {
  left: 'left',
  right: 'right'
}

const Alignments = {
  left: 'left',
  right: 'right',
  center: 'center'
}

const Sizes = {
  large: 'large',
  medium: 'medium',
  small: 'small'
}

const Types = {
  primary: 'primary',
  primaryLight: 'primary-light',
  secondary: 'secondary',
  tertiary: 'tertiary',
  hollow: 'hollow',
  destructive: 'destructive'
}

const Button = ({
  className,
  url,
  external,
  submit,
  type = 'primary',
  icon,
  iconSide = 'right',
  loading,
  fullWidth,
  size = 'medium',
  textAlign = 'center',
  ariaLabel,
  ariaControls,
  onClick,
  children,
  disabled,
  testId,
  buttonType = 'button'
}) => {
  const theIcon =
    icon && icon !== 'none' ? (
      <span className={styles['ds-button__icon']} aria-label={icon}>
        <Icon icon={icon} focusable={false} ariaHidden={true} />
      </span>
    ) : null

  const classNames = ClassNames([
    styles[`ds-button`],
    `text-align-${textAlign}`,
    `${fullWidth ? 'display-block' : 'display-inline-flex'}`,
    `${loading ? 'loading' : ''}`,
    className,
    styles[`ds-button__${Types[type]}`],
    styles[`ds-button__${Sizes[size]}`]
  ])

  const Component = ({ componentType }) => {
    const ComponentType = componentType
    return (
      <ComponentType
        data-testid={`${testId ? testId : 'button'}`}
        type={componentType === 'button' ? (submit ? 'submit' : 'button') : null}
        disabled={disabled}
        className={classNames}
        data-ds-icon={icon !== 'none' ? 'true' : null}
        data-ds-icon-side={icon !== 'none' ? iconSide : null}
        aria-label={ariaLabel ? ariaLabel : null}
        aria-controls={ariaControls ? ariaControls : null}
        onClick={onClick ? onClick : null}
      >
        <span className={ClassNames([styles['root'], `${loading ? 'fade-out' : ''}`])}>
          {iconSide === 'left' && icon !== 'none' && theIcon}
          <span className={styles['ds-button__text']}>{children}</span>
          {iconSide === 'right' && icon !== 'none' && theIcon}
        </span>
        {loading && <Loading />}
      </ComponentType>
    )
  }

  return url ? (
    <Link href={url} external={external ? true : null} ariaLabel={ariaLabel}>
      <Component componentType="div" />
    </Link>
  ) : (
    <Component componentType={buttonType} />
  )
}

export default Button

Button.propTypes = {
  url: PropTypes.string,
  external: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(Types)),
  icon: PropTypes.any,
  iconSide: PropTypes.oneOf(Object.keys(IconSides)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  submit: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(Sizes)),
  textAlign: PropTypes.oneOf(Object.keys(Alignments)),
  onClick: PropTypes.func
}
