import PropTypes from 'prop-types'
import styles from './Icon.module.scss'
import Linkedin from './icons/Linkedin'
import Envelope from './icons/Envelope'
import Twitter from './icons/Twitter'
import Facebook from './icons/Facebook'
import ApplePodcast from './icons/ApplePodcast'
import GooglePodcast from './icons/GooglePodcast'
import SpotifyPodcast from './icons/SpotifyPodcast'
import StitcherPodcast from './icons/StitcherPodcast'
import Youtube from './icons/Youtube'
import Instagram from './icons/Instagram'
import Search from './icons/Search'
import Pdf from './icons/Pdf'

const map = {
  linkedin: Linkedin,
  envelope: Envelope,
  twitter: Twitter,
  facebook: Facebook,
  applePodcast: ApplePodcast,
  googlePodcast: GooglePodcast,
  spotifyPodcast: SpotifyPodcast,
  stitcherPodcast: StitcherPodcast,
  instagram: Instagram,
  youtube: Youtube,
  search: Search,
  pdf: Pdf
}

//import 'eva-icons/style/eva-icons.css'
function Icon({
  icon = '',
  size = 'medium',
  className = '',
  focusable = null,
  ariaHidden = null,
  onClick = () => {},
  label
}) {
  const Component = map[icon]
  if (!Component) {
    return <></>
  }
  return (
    <span
      className={`${styles['ds-icon']} ${className} ${size}`}
      focusable={focusable}
      aria-hidden={ariaHidden}
      onClick={onClick}
    >
      <Component />
      <span className="sr-only">{label || icon}</span>
    </span>
  )
}

Icon.propTypes = {
  icon: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.oneOf(
    Object.keys({
      default: null,
      medium: 'medium',
      large: 'large'
    })
  )
}

export default Icon
