import PropTypes from 'prop-types'

import styles from './Loading.module.scss'

const Loading = ({ className = '' }) => {
  return <div className={styles['ds-loading']}></div>
}

Loading.propTypes = {}

export default Loading
