import React, { useContext, useEffect, useState } from 'react'
import Script from 'next/script'
import Head from 'next/head'
import Layout from 'layouts/Layout'
import SpeedBump from 'components/SpeedBump'
import { NavStateContext } from 'providers/nav'
import { PickFirst } from 'services/utils'
import { CompileStructuredData } from 'services/opengraph'
import { CdnUrl } from 'services/image.service'
import BannerAlert from 'components/BannerAlert'
import { nanoid } from 'nanoid'
import { useCookies } from 'react-cookie'

const Backgrounds = {
  balloon: CdnUrl('balloon-hero.png', {format: 'auto', quality: 'auto'}),
  bullseye: CdnUrl('bullseye_rld9ag.svg'),
  moneybag: CdnUrl('money-bag.png', {format: 'auto', quality: 'auto'}),
  compass: CdnUrl('compass_gfdb3a.svg'),
  lion: CdnUrl('lion_eq0obk.svg'),
  tree: CdnUrl('tree_ooh3kd.svg'),
  binoculars: CdnUrl('binoculars_vzi7qy.svg'),
  handshake: CdnUrl('handshake_gowftf.svg'),
  lightbulb: CdnUrl('lightbulb_msa6mq.svg'),
  lock: CdnUrl('lock_xes1la.svg'),
  mountainhouse: CdnUrl('mountainhouse_ssm7ry.svg'),
  mountainroad: CdnUrl('mountainroad_dsmlhs.svg'),
  mountains: CdnUrl('mountains_qoovcx.svg'),
  hand: CdnUrl('hand_t1vlw8.svg'),
  plane: CdnUrl('paper-plane_c5cusi.png')
}

const PageLayout = ({
  children,
  title,
  meta = {},
  applicationSettings = {},
  pageTypes = ['website', 'webpage'],
  ...theRest
}) => {
  const backgroundImageName = meta?.background?.image?.[0]?.imageName
  const backgroundImage = Backgrounds[backgroundImageName]
  const [showSpeedBump, setShowSpeedBump] = useState(null)

  useEffect(() => {
    const host = location.host

    const links = document.querySelectorAll('a')
    links.forEach((link) => {
      if (
        !link.href.includes(host) &&
        !link.href.includes('mailto:') &&
        !link.href.includes('tel:') &&
        !link.href.includes('https://media.firstbusiness.com/image/upload') &&
        !link.href.includes('https://media.firstbusiness.bank/image/upload') &&
        !link.href.includes(process.env.IMAGE_CDN) &&
        !applicationSettings?.speedBumpWhitelist?.some((item) => link.href === item.url)
      )
        link.addEventListener(
          'click',
          (ev) => {
            ev.preventDefault()
            setShowSpeedBump(ev.currentTarget.href)
            return false
          },
          false
        )
    })
  }, [])

  meta.title = title || meta.title
  const { navOpen } = useContext(NavStateContext) || {}
  const appMeta =
    (Array.isArray(applicationSettings.meta)
      ? PickFirst(applicationSettings.meta)
      : applicationSettings.meta) || {}

  useEffect(() => {
    if (navOpen) {
      document.querySelector('body').classList.add('no-scroll')
      if (typeof window !== 'undefined' && document.querySelector('aside')) {
        window.scrollTo({
          top: document.querySelector('aside')?.scrollHeight || 0,
          behavior: 'smooth'
        })
      }
    } else {
      document.querySelector('body').classList.remove('no-scroll')
    }
  }, [navOpen])

  const fullUrl = `${appMeta.siteurl}/${meta.url !== '/' ? meta.url : ''}`
  
  const [cookies, setCookie] = useCookies(['alertDismissed']);
  const alertDismissed = cookies.alertDismissed || null
  const date = new Date();
  const cookieExpiration = date.setDate(date.getDate() + 1);

  return (
    <>
      <Head>

        {/* OneTrust Cookies Consent Notice start for firstbusiness.bank  */}
        <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          charSet="UTF-8" 
          data-domain-script="90a6d4fd-f4ad-4521-b28f-6bdc74289641"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `function OptanonWrapper() { }`
          }}
        />
        {/* OneTrust Cookies Consent Notice end for firstbusiness.bank */}
        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TGLVNPJ');`
          }}
        />
        {/* End Google Tag Manager */}
        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://munchkin.marketo.net" />
        <link rel="preconnect" href="https://media.firstbusiness.bank" />
        <link rel="dns-prefetch" href="https://use.typekit.net" />
        <link rel="dns-prefetch" href="https://munchkin.marketo.net" />
        <link rel="dns-prefetch" href="https://media.firstbusiness.bank" />
        <link rel="preload" href="https://use.typekit.net/mkv2oxu.css" as="style" />
        <link rel="stylesheet" href="https://use.typekit.net/mkv2oxu.css" />
        {backgroundImage && <link rel="preload" as="image" href={backgroundImage} />}
        <title>
          {meta.title} - {appMeta.sitename}
        </title>
        {/* <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        /> */}
        <meta name="description" content={meta.description || ''} />
        <link rel="canonical" href={fullUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={meta.title || ''} />
        <meta property="og:description" content={meta.description || ''} />
        <meta property="og:url" content={fullUrl || ''} />
        <meta property="og:site_name" content={appMeta.sitename || ''} />
        {/* <meta property="fb:app_id" content={appMeta.facebookAppId} /> */}
        <meta
          property="og:image"
          content={
            meta.image && meta.image?.fileName
              ? CdnUrl(meta.image.fileName, {quality: 'auto'})
              : appMeta.defaultimage
              ? appMeta.defaultimage
              : ''
          }
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:description" content={meta.description || ''} />
        <meta property="twitter:title" content={meta.title || ''} />
        <meta
          property="twitter:image"
          content={
            meta.image && meta.image?.fileName
              ? CdnUrl(meta.image.fileName, {quality: 'auto'})
              : appMeta.defaultimage
              ? appMeta.defaultimage
              : ''
          }
        />

        <link rel="manifest" href="/manifest.json" />
        <script src="/ie.js" strategy="beforeInteractive" />
        <meta name="theme-color" content="#a4347b" />
        {applicationSettings.specialAnnouncement && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: applicationSettings.specialAnnouncement }}
          />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: CompileStructuredData({ meta, applicationSettings: appMeta, pageTypes })
          }}
        />

        {(process.env.DEVELOPMENT_ENV !== 'production') || meta?.noIndex ? (
          <>
            {/* <script
              src={`https://www.bugherd.com/sidebarv2.js?apikey=${process.env.BUGHERD_KEY}`}
              async={true}
            /> */}
            <meta name="robots" content="noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
          </>
        ) : <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>}

        {process.env.DEVELOPMENT_ENV === 'production' ? (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `(function() { var didInit=false; function initMunchkin() { if(didInit===false) { didInit=true; Munchkin.init('880-QNO-957', { httpsOnly: true, asyncOnly: true }); } } var s=document.createElement('script'); s.type='text/javascript' ; s.async=true; s.src='https://munchkin.marketo.net/munchkin.js' ; s.onreadystatechange=function() { if (this.readyState=='complete' || this.readyState=='loaded' ) { initMunchkin(); } }; s.onload=initMunchkin; document.getElementsByTagName('head')[0].appendChild(s); })();`
              }}
            />
          </>
        ) : null}
        <script
          dangerouslySetInnerHTML={{
          __html: `(function () {
              var zi = document.createElement('script');
              zi.type = 'text/javascript';
              zi.async = true;
              zi.referrerPolicy = 'unsafe-url';
              zi.src = 'https://ws.zoominfo.com/pixel/61a78c25f46f5a00191a9481';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(zi, s);
            })();`
          }}
        />
        {/* Vidyard global script */}
        <script type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script>
        
        {/* Floodlight */}
        {/* Global */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=DC-13333447"></script>
        <script
          dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'DC-13333447');`
          }}
        ></script>

        {/* Floodlight */}

      </Head>
      <Layout
        overflow={fullUrl.includes('/podcast') ? true : false}
        backgroundConfig={meta.background || {}}
        hasAnnouncement={applicationSettings.notificationsAndAlerts?.length > 0 ? true : false}
      >
        {applicationSettings.notificationsAndAlerts?.length > 0 &&
          applicationSettings.notificationsAndAlerts.map((alert) => {
            if (
              (alert.endDate && new Date() >= new Date(alert.endDate)) ||
              (alert.startDate && new Date() < new Date(alert.startDate)) ||
              alertDismissed
            ) {
              return null
            }
            return (
              <BannerAlert
                key={nanoid()}
                onDismiss={() => {
                  setCookie('alertDismissed', 1, [{expires: cookieExpiration}, {maxAge: 86000 * 2}])
                }}
              >
                {alert.title && (
                  <>
                    <strong>{alert.title}:</strong>{' '}
                  </>
                )}
                <div
                  className="display-inline-block"
                  dangerouslySetInnerHTML={{ __html: alert.body }}
                />
              </BannerAlert>
            )
          })}
        {children}
        {showSpeedBump && (
          <SpeedBump
            onAccept={() => {
              window.open(showSpeedBump)
            }}
            onCancel={() => setShowSpeedBump(null)}
          />
        )}
      </Layout>
    </>
  )
}

export default PageLayout
