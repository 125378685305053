const ApplePodcast = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 300 300"
    >
      <defs />
      <defs>
        <linearGradient id="a">
          <stop offset="0" stopColor="#822cbe" />
          <stop offset="1" stopColor="#d772fb" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="458.9"
          x2="456.4"
          y1="303.8"
          gradientTransform="translate(-309)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#a"
        />
      </defs>
      <rect width="300" height="300" fill="url(#b)" ry="66.7" />
      <path
        fill="#fff"
        d="M141 263c-9-4-11-8-15-33-4-30-5-48-2-54 3-8 12-12 24-12 13 0 22 4 25 12 2 6 1 24-3 54-3 20-4 26-8 29-6 5-14 6-21 4zm-38-31A104 104 0 0172 65c20-21 47-33 76-33 30 0 56 11 76 32a104 104 0 0128 91 106 106 0 01-69 82l-1-6c1-7 2-9 6-10a96 96 0 0050-62c3-11 3-34 0-44-9-34-37-60-71-67-9-2-27-2-37 0-34 7-62 34-71 69-2 9-2 32 0 42a96 96 0 0050 62c4 2 5 3 6 11 1 4 0 5-1 5l-11-4zm0-40c-10-9-20-23-23-38-3-9-3-25 0-34 6-23 23-42 47-50a71 71 0 0191 76 70 70 0 01-31 50v-15l5-6a57 57 0 00-34-94h-18c-13 2-23 7-31 16a57 57 0 00-4 78l6 6v8l-1 7-7-4zm35-41c-9-4-14-12-14-22s5-18 14-22c5-3 15-3 21 0s11 9 13 16c6 19-15 37-34 28z"
      />
      <circle cx="149.9" cy="129.7" r="24.9" fill="#fff" />
      <path
        fill="#fff"
        d="M152 164l9 2 8 4 5 5 2 9-1 19a712 712 0 01-8 52l-5 7-6 3h-12c-3 0-5-2-7-4-2-1-4-4-5-7l-3-16a884 884 0 01-5-58l2-6a18 18 0 019-6l6-2 8-1h3z"
      />
    </svg>
  )
}

export default ApplePodcast
