import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import styles from './SpeedBump.module.scss'

import Button from 'basics/Button'
import { useEscape } from 'hooks/useEscape'

const SpeedBump = ({ className, onAccept = () => {}, onCancel = () => {} }) => {
  useEscape({ onCancel })
  return (
    <div className={styles['speed-bump']}>
      <aside className={styles['speed-bump__content']}>
        <h1>You are leaving the First Business Bank website.</h1>
        <p>
          You are now leaving the First Business Bank website and will be entering a third party
          site which is not owned nor operated by First Business Bank. The site is not designed,
          maintained, nor the responsibility of First Business Bank or its affiliates.
        </p>
        <div>
          <Button
            onClick={() => {
              onAccept()
              onCancel()
            }}
          >
            Continue to THIRD PARTY WEB SITE
          </Button>
        </div>
        <Button type="hollow" onClick={onCancel}>
          Back to firstbusiness.bank
        </Button>
      </aside>
    </div>
  )
}

SpeedBump.propTypes = {}

export default SpeedBump
