const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="20">
    <path
      fill="#cccccc"
      d="M1216 832q0-185-131.5-316.5T768 384 451.5 515.5 320 832t131.5 316.5T768 1280t316.5-131.5T1216 832zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225T64 832t55.5-273.5 150-225 225-150T768 128t273.5 55.5 225 150 150 225T1472 832q0 220-124 399l343 343q37 37 37 90z"
    />
  </svg>
)

export default Search
