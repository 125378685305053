import React from 'react'
import PropTypes from 'prop-types'
import { ClassNames } from 'services/classname.service'
import { AddTrailingSlash } from 'services/utils'

const IgnoreLinks = [
  'https://d2iaewiscy5kyb.cloudfront.net',
  'https://develop.d1uifm4f0mte7h.amplifyapp.com',
  'https://d2idea1kzvufhy.cloudfront.net',
  'https://d21wyoljxl2sqn.cloudfront.net'
]

const Link = ({
  href = '',
  external = false,
  className,
  children,
  ariaLabel,
  ariaHidden,
  chevron = false,
  alt,
}) => {
  const isPreview = process.env.IS_PREVIEW
  if (href && href[0] === '#') {
    external = false
  }
  if (href) {
    IgnoreLinks.forEach((url) => {
      href = href.replace(url, '')
    })

    href = external ? href : AddTrailingSlash(href)
  }
  return (
    <>
      {!isPreview && (
        <a
          href={href}
          data-testid="link"
          target={external ? '_blank' : null}
          rel={external ? 'noopener noreferrer nofollow' : null}
          aria-label={ariaLabel ? ariaLabel : null}
          className={ClassNames([className, chevron ? 'chevron' : ''])}
          aria-hidden={ariaHidden ? 'true' : null}
          tabIndex={ariaHidden ? '-1' : null}
          alt={alt}
        >
          {children}
        </a>
      )}

      {isPreview && (
        <a
          href={href}
          data-testid="link"
          target={external ? '_blank' : null}
          rel={external ? 'noopener noreferrer nofollow' : null}
          aria-label={ariaLabel ? ariaLabel : null}
          className={ClassNames([className, chevron ? 'chevron' : ''])}
          aria-hidden={ariaHidden ? 'true' : null}
          tabIndex={ariaHidden ? '-1' : null}
          alt={alt}
        >
          {children}
        </a>
      )}
    </>
  )
}

Link.propTypes = {
  href: PropTypes.string,
  external: PropTypes.bool,
  chevron: PropTypes.bool
}

export default Link
