import React, { useState, useContext } from 'react'
import styles from './Hamburger.module.scss'
import { ClassNames } from 'services/classname.service'
import { NavStateContext } from 'providers/nav'

const Hamburger = ({ className, setOpen, open }) => {
  const [menuOpen, setMenuOpen] = useState(open)
  const { navOpen, toggleNav } = useContext(NavStateContext) || {}
  const handleKeyDown = () => {
    toggleNav()
  }
  return (
    <div
      className={ClassNames([styles[`ds-hamburger`], 'display-none md-display-block', className])}
      onClick={() => toggleNav()} onKeyDown={handleKeyDown}
    >
      <svg
        viewBox="0 0 30 22"
        width="30"
        className={ClassNames([styles['lines'], navOpen ? styles['active'] : ''])}
      >
        <path
          d="M1 0h28a1 1 0 011 1 1 1 0 01-1 1H1a1 1 0 01-1-1 1 1 0 011-1z"
          className={ClassNames([styles['line'], styles['line-1']])}
        />
        <path
          d="M1 10h28a1 1 0 011 1 1 1 0 01-1 1H1a1 1 0 01-1-1 1 1 0 011-1z"
          className={ClassNames([styles['line'], styles['line-2']])}
        />
        <path
          d="M1 20h28a1 1 0 011 1 1 1 0 01-1 1H1a1 1 0 01-1-1 1 1 0 011-1z"
          className={ClassNames([styles['line'], styles['line-3']])}
        />
      </svg>
    </div>
  )
}

Hamburger.propTypes = {}

export default Hamburger
