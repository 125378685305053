import PropTypes from 'prop-types'
import { ClassNames } from 'services/classname.service';

import styles from './SingleColumn.module.scss'
import Container from '../../containers/Container'

const SingleColumn = ({
  children,
  className = '',
  containerOptions = {},
  id,
  center = true
}) => {
  return (
    <Container {...containerOptions} id={id} center={ center }>
      <section className={ ClassNames([ 'ds-single-column', center ? 'mx-auto' : '', className ]) }>{children}</section>
    </Container>
  )
}

SingleColumn.propTypes = {}

export default SingleColumn
