const Facebook = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#FFFFFF"
        d="M18 32V18h6l1-6h-7V9c0-2 1.002-3 3-3h3V0h-5c-5 0-7 3-7 8v4H6v6h6v14h6z"
      />
    </svg>
  )
}

export default Facebook
