import PropTypes from 'prop-types'

import styles from './Spacer.module.scss'

const Spacer = ({ size = 1, mdSize, smSize, className }) => {
  // if (!mdSize && mdSize !== 0) {
  //   mdSize = size
  // }
  // if (!smSize && smSize !== 0) {
  //   smSize = size
  // }
  return (
    <div
      className={`${styles['ds-spacer']} mt-${size} md-mt-${mdSize} sm-mt-${smSize} ${className}`}
    ></div>
  )
}

Spacer.propTypes = {
  size: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]),
  mdSize: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20
  ]),
  smSize: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20
  ])
}

export default Spacer
