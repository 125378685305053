import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './GridContainer.module.scss'
import { ClassNames } from 'services/classname.service'

const GridContainer = ({
  gridSize,
  gridGap = 12,
  gridColumnGap,
  gridRowGap,
  gridElementSize = '1fr',
  maxWidth,
  children,
  className = ''
}) => {
  //auto-fit, minmax(240px, 1fr)
  return (
    <section
      style={{
        '--grid-size': gridSize,
        '--grid-column-gap': `${!gridColumnGap ? gridGap : gridColumnGap}px`,
        '--grid-row-gap': `${!gridRowGap ? gridGap : gridRowGap}px`,
        '--grid-element-size': `${gridElementSize}`,
        'maxWidth': `${maxWidth}`
      }}
      className={ClassNames([styles['ds-grid'], `display-grid md-grid-single-column ${className}`])}
    >
      {children}
    </section>
  )
}

GridContainer.propTypes = {
  gridSize: PropTypes.number,
  gridGap: PropTypes.any,
  className: PropTypes.string
}

export default GridContainer
