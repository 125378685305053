import PropTypes from 'prop-types'
import styles from './Image.module.scss'
import { ClassNames } from '../../services/classname.service'
import { ImageConfig } from 'services/image.service'
import { PickFirst } from 'services/utils'

function Image({
                 src = '',
                 alt = '',
                 className,
                 type = 'cloudinary',
                 align = 'flex-start',
                 objectFit,
                 imageProps,
                 metadata,
                 decoding = 'async',
                 display
               }) {
  if (!imageProps) {
    imageProps = {}
  }
  if (Array.isArray(src)) {
    src = PickFirst(src)
  }
  let prefix = src && src.toString().includes('http') ? '' : ImageConfig.prefix[type](imageProps)
  return (
    <div
      className={ClassNames([
        styles[`ds-image`], display ? display : 'display-flex',
        `justify-content-center align-items-${align}`,
        className
      ])}
    >
      {src && (
        <picture className={ClassNames([`ds-image__picture`, className])}>
          <img
            src={`${prefix}${src}`}
            alt={(alt || '').trim()}
            loading="lazy"
            decoding={decoding}
            onLoad={(ev) => {}}
          />
        </picture>
      )}
    </div>
  )
}

Image.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.string,
  objectFit: PropTypes.oneOf(
    Object.keys({
      cover: 'cover',
      contain: 'contain',
      fill: 'fill'
    })
  )
}

export default Image
