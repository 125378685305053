import PropTypes from 'prop-types'
import styles from './Nav.module.scss'
import Icon from 'basics/Icon'
import Container from 'containers/Container'
import Link from 'basics/Link'
import { ClassNames } from 'services/classname.service'
import { nanoid } from 'nanoid'
import { useRef } from 'react'

const Nav = ({ className, navLinks = [], route = [] }) => {
  const ref = useRef()
  return (
    <Container className={className} wide={true}>
      <div className={styles['ds-nav']}>
        <div className={ClassNames([styles[('login-link', 'mobile-login')], 'mx-2'])}>
          <Link href="/login" className="font-weight-bold">
            Login
          </Link>
        </div>
        <nav>
          <ul className="display-flex">
            {navLinks.map((link, idx) => {
              const page = (link.page && link.page[0]) || {}
              const title = link.linkTitle || page.name
              const external = link.alternateLink ? true : null
              const destination = link.alternateLink || `/${page.url}`
              return (
                <li
                  key={nanoid()}
                  className={
                    page.url && page.url?.split('/')[0] === route[0] ? styles['active'] : ''
                  }
                >
                  <Link href={destination} external={external}>
                    {title}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>

        <div className={ClassNames([styles['ds-nav__utils'], 'display-flex'])}>
          <div className={styles['search']}>
            <div className={styles['horizontal-divider']}></div>
            <form
              action="/search"
              method="get"
              onSubmit={(ev) => {
                ev.preventDefault()
                location.href = `/search/?keyword=${ref.current?.value}`
                return false
              }}
            >
              <input
                ref={ref}
                className={styles['search__input']}
                placeholder="Search..."
                type="search"
                name="keyword"
              />
              <button type="submit">
                <span className="sr-only">Search</span>
                <Icon icon="search" className={styles['search__button']} />
              </button>
            </form>
            <div className={styles['horizontal-divider']}></div>
          </div>
          <div className={ClassNames([styles[('login-link', 'desktop-login')], 'mx-2'])}>
            <Link href="/login" className="font-weight-bold">
              Login
            </Link>
          </div>
        </div>
      </div>
    </Container>
  )
}

Nav.propTypes = {}

export default Nav
